import ApiService from "@/core/services/api.service";
import { getToken } from "@/core/services/jwt.service.js";
import { getData } from "@/core/services/local.service";
export const GetQuotation = (uuid, revise = false, duplicate = false) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		let params = {};
		const local_uuid = getData("local_uuid");
		if (revise || duplicate) {
			params = { revise, local_uuid, duplicate };
		}

		ApiService.query(`quotation/${uuid}`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const DeleteService = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.delete(`quotation/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const UpdateChangePrice = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.put(`price-update/quotation/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const CreateQuotation = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("quotation", params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateQuotation = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`quotation/${uuid}`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetLineItem = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(`line-item-options-quotation`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const SearchLineItem = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(`line-item`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
/* http://127.0.0.1:8000/api/v1/export-line-items/quotation/a2d04f02-8c50-4301-99bb-6fe1ca0a1ebb */
export const ExportLineItem = (uuid) => {
	return new Promise((resolve, reject) => {
		const token = getToken();
		const api_url = process.env.VUE_APP_API_URL;
		const file_url = `${api_url}export-line-items/quotation/${uuid}?token=${token}`;
		const pdf = window.open(file_url, "_blank");
		if (pdf) {
			resolve(true);
		} else {
			reject(false);
		}
	});
};
/* export const DownloadPDF = ({ url, uuid }) => {
	return new Promise((resolve, reject) => {
		const token = getToken();
		const api_url = process.env.VUE_APP_API_URL;
		const pdf_url = `${api_url}${url}${uuid}?token=${token}`;
		const pdf = window.open(pdf_url, "_blank");
		if (pdf) {
			resolve(true);
		} else {
			reject(false);
		}
	});
}; */
export const UpdateOrCreateLineItems = (url, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(url, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const DeleteLineItem = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		/* http://127.0.0.1:8000/api/v1/line-item/delete/single */
		ApiService.patch(`line-item/delete/single`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const DeleteAllLineItems = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		/* http://127.0.0.1:8000/api/v1/line-item/delete/all */
		ApiService.put(`line-item/delete/all`, payload)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const ImportLineItems = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		/* http://127.0.0.1:8000/api/v1/line-item/import */
		ApiService.upload(`line-item/import`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetCustomer = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(`quotation/get-options`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const getQuotationLineItemByQuotation = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(`quotation/get-options`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetLocalData = (uuid, revise = false) => {
	return new Promise((resolve, reject) => {
		let params = {};
		if (revise) {
			params = { revise };
		}

		ApiService.setHeader();
		/* http://127.0.0.1:8000/api/v1/line-item/previous-temp-items/60a259b0-d38f-11ed-b9a5-0bb5206f5381 */
		ApiService.query(`line-item/previous-temp-items/${uuid}`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
